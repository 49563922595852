<template>
  <div class="resource-list-dashboard-container">
    <vs-popup title="" :active.sync="showCreateResourceModal" @close="clearFields" class="create-resource-modal">
      <h4>Create Resource</h4>
      <form-wizard
        ref="createResourceWizard"
        color="rgba(var(--vs-primary), 1)"
        :title="null"
        :subtitle="null"
        :hide-buttons="true"
      >
        <div slot="title"></div>
        <tab-content>
          <div id="div-with-loading" class="vs-con-loading__container">
            <form data-vv-scope="create-resource-main-info" class="create-resource-form-general">
                <div class="resource-type">
                  <vs-radio
                    :class="{ 'selected-type': resource.type === 'person' }"
                    v-model="resource.type"
                    name="type"
                    vs-value="person"
                    v-validate="'required'"
                  >
                    <span class="resource-type-title">Person</span>
                    <span class="resource-type-description">E.g advisors, employees or agents</span>
                  </vs-radio>
                  <vs-radio
                    :class="{ 'selected-type': resource.type === 'item-asset' }"
                    v-model="resource.type"
                    vs-value="item-asset"
                    name="type"
                    v-validate="'required'"
                  >
                    <span class="resource-type-title">Item/asset</span>
                    <span class="resource-type-description">E.g meeting rooms, desks or tools</span>
                  </vs-radio>
                </div>
                <div>
                  <span
                    v-show="errors.has('create-resource-main-info.type')"
                    class="text-danger"
                  >{{ errors.first('create-resource-main-info.type') }}</span>
                </div>

              <div class="vx-row w-full">
                <vs-input
                  v-validate="'required'"
                  name="name"
                  label="Name"
                  v-model="resource.name"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('create-resource-main-info.name')"
                  class="text-danger"
                >{{ errors.first('create-resource-main-info.name') }}</span>
              </div>
              <div class="vx-row w-full">
                <vs-input
                  v-validate="'required'"
                  name="email"
                  label="Email"
                  v-model="resource.email"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('create-resource-main-info.email')"
                  class="text-danger"
                >{{ errors.first('create-resource-main-info.email') }}</span>
              </div>
              <div class="vx-row w-full timezone-container">
                <v-select
                  placeholder="Timezone"
                  v-model="resource.timezone"
                  name="timezone"
                  v-validate="'required'"
                  :options="timezones"
                  class="w-full"
                />
                <span
                  v-show="errors.has('create-resource-main-info.timezone')"
                  class="text-danger"
                >{{ errors.first('create-resource-main-info.timezone') }}</span>
              </div>
              <vs-button class="mt-6 ml-auto flex" @click.prevent="goToConfigureResourceStep">Create</vs-button>
            </form>
          </div>
        </tab-content>
        <tab-content>
          <form data-vv-scope="create-resource-details" class="create-resource-form-details">
            <vs-row
              vs-align="flex-start"
              vs-type="flex" vs-justify="space-between">
              <div class="resource-field-container w-full">
                <vs-input
                  v-validate="'required'"
                  name="scheduleName"
                  label="Schedule Name"
                  v-model="practionerScheduleForm.schedule_name"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('create-resource-details.schedule_name')"
                  class="text-danger"
                >{{ errors.first('create-resource-details.schedule_name') }}</span>
              </div>
            </vs-row>
            <vs-button class="mt-6 ml-auto flex" @click.prevent="goToAddTimeslotsStep">Confirm</vs-button>
          </form>
        </tab-content>
        <tab-content>
          <div class="create-resource-add-timeslots">
            <h4>Recurring working hours</h4>
            <div class="recurring-working-hours-description">
              Define which weekly recurring times the resource is available. The working hours apply to all projects and that include the resource.
            </div>
            <div class="recurring-working-hours-widget-container">
              <div class="add-recurring-working-hours-title">
                Add recurring working hours
              </div>
              <form data-vv-scope="add-timeslot" class="add-timeslot-form">
<!--                <vs-row vs-align="justify" vs-type="flex" vs-justify="space-between" class="mb-8">-->
<!--                  <div class="vx-col sm:w-1/2 w-full">-->
<!--                    <label for="startDate" class="start-date-label">Start date</label>-->
<!--                    <datepicker placeholder="Start date"-->
<!--                                format="dd/MM/yyyy"-->
<!--                                v-model="startDate"-->
<!--                                :inline="true"-->
<!--                                :disabled-dates="disabledDates"-->
<!--                    >-->
<!--                    </datepicker>-->
<!--                    <input type="hidden" name="startDate" v-validate="'required'" v-model="startDate">-->
<!--                    <span-->
<!--                      v-show="errors.has('add-timeslot.startDate')"-->
<!--                      class="text-danger"-->
<!--                    >{{ errors.first('add-timeslot.startDate') }}</span>-->
<!--                  </div>-->
<!--                  <div class="vx-col sm:w-1/2 w-full">-->
<!--                    <label for="startDate" class="end-date-label">End date</label>-->
<!--                    <datepicker placeholder="END date"-->
<!--                                format="dd/MM/yyyy"-->
<!--                                v-model="endDate"-->
<!--                                :inline="true"-->
<!--                                :disabled-dates="disabledDates"-->
<!--                    >-->
<!--                    </datepicker>-->
<!--                    <input type="hidden" name="endDate" v-validate="'required'" v-model="endDate">-->
<!--                    <span-->
<!--                      v-show="errors.has('add-timeslot.endDate')"-->
<!--                      class="text-danger"-->
<!--                    >{{ errors.first('add-timeslot.endDate') }}</span>-->
<!--                  </div>-->
<!--                </vs-row>-->
                <vs-row v-for="(item, index) in practionerScheduleForm.time_slots" :key="index" vs-align="justify" vs-type="flex" vs-justify="space-between" class="recurring-working-hours-widget mt-4">
                  <div class="resource-field-container">
                    <v-select
                      placeholder="Day"
                      v-model="item.day"
                      name="status"
                      v-validate="'required'"
                      :options="daysOfWeek"
                    />
                    <input type="hidden" name="dayOfWeek" v-validate="'required'" v-model="dayOfWeek">
                    <span
                      v-show="errors.has('add-timeslot.dayOfWeek')"
                      class="text-danger"
                    >{{ errors.first('add-timeslot.dayOfWeek') }}</span>
                  </div>
                  <div class="resource-field-container start-time-slot-widget">
                    <vue-timepicker v-model="item.from_time" :minute-interval="15" :hour-range="[[9, 18]]"></vue-timepicker>
                    <input type="hidden" name="startTime" v-validate="'required'" v-model="startTime">
                    <span
                      v-show="errors.has('add-timeslot.startTime')"
                      class="text-danger"
                    >{{ errors.first('add-timeslot.startTime') }}</span>
                  </div>
                  <div class="resource-field-container end-time-slot-widget">
                    <vue-timepicker v-model="item.to_time" :minute-interval="15" :hour-range="[[9, 18]]"></vue-timepicker>
                    <input type="hidden" name="endTime" v-validate="'required'" v-model="endTime">
                    <span
                      v-show="errors.has('add-timeslot.endTime')"
                      class="text-danger"
                    >{{ errors.first('add-timeslot.endTime') }}</span>
                  </div>
                  <div class="w-[20px]">
                    <vs-button radius color="danger" icon="delete" @click="removeSchedule(index)"></vs-button>
                  </div>
                </vs-row>
                <div class="mt-3">
                  <button class="px-4 py-2 rounded-lg text-white bg-success border-0" @click.prevent="addNewScheduleForm">+</button>
                </div>
                <div class="resource-field-container flex justify-end w-full">
                  <!--                    <button class="add-timeslot-btn" :class="{ 'disabled-btn': errors.any() || !canAddTimeslot }"  @click.prevent="addTimeslot" :disabled="errors.any() || !canAddTimeslot">Add</button>-->
                  <button class="mt-4 px-10 py-3 rounded text-white bg-primary border-0" @click.prevent="createPractionerSchedule" :disabled="errors.any()">Add</button>
                </div>
              </form>
              <div class="recurring-working-hours-list-container">
                <div id="div-with-loading" class="vs-con-loading__container">
                  <div class="recurring-working-hours-list" v-if="personnelResourceAvailableTimeslots.length">
                    <div class="recurring-working-hours-item" v-for="(timeslot, index) in personnelResourceAvailableTimeslots" :key="index">
                      <div class="timeslot">
                        {{ timeslot.title }}
                      </div>
                      <vs-icon>&#10006;</vs-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </vs-popup>
    <section class="resources-list">
      <div class="resources-list-header">
        <button class="create-resource-button generic-button" @click="showModal()">Create resource</button>
      </div>
      <h4>Personnel Resources</h4>
      <div class="resources-list-content">
        <vs-table v-model="selected" pagination :data="practioners">
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="type">Practioner Fullname</vs-th>
            <vs-th sort-key="expertise">Type</vs-th>
<!--            <vs-th sort-key="language">Language</vs-th>-->
            <vs-th sort-key="department">Department</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                {{ data[indextr].first_name }}
              </vs-td>
              <vs-td :data="data[indextr].custom_resource_type">
                {{ data[indextr].custom_resource_type }}
              </vs-td>
<!--              <vs-td :data="data[indextr].language">-->
<!--                {{ data[indextr].language }}-->
<!--              </vs-td>-->
              <vs-td :data="data[indextr].department">
                {{ data[indextr].department }}
              </vs-td>
              <vs-td :data="data[indextr].organizationId">
                <i class="vs-icon notranslate icon-scale material-icons null" @click="showCreateTimeslotsModal(data[indextr].id, 'personnel')">chevron_right</i>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </section>
    <section class="resources-list">
      <h4>Equipment Resources</h4>
      <div class="resources-list-content">
        <vs-table v-model="selected" pagination :data="resourceEquipmentData">
          <template slot="thead">
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="type">Type</vs-th>
            <vs-th sort-key="expertise">Expertise</vs-th>
            <vs-th sort-key="language">Language</vs-th>
            <vs-th sort-key="department">Department</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].name">
                {{ data[indextr].name }}
              </vs-td>
              <vs-td :data="data[indextr].type">
                {{ data[indextr].type }}
              </vs-td>
              <vs-td :data="data[indextr].expertise">
                {{ data[indextr].expertise }}
              </vs-td>
              <vs-td :data="data[indextr].language">
                {{ data[indextr].language }}
              </vs-td>
              <vs-td :data="data[indextr].department">
                {{ data[indextr].department }}
              </vs-td>
              <vs-td :data="data[indextr].organizationId">
                <i class="vs-icon notranslate icon-scale material-icons null" @click="showCreateTimeslotsModal(data[indextr].id, 'equipment')">chevron_right</i>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </section>
  </div>
</template>

<script>
import moduleResource from '@/store/resource/moduleResource.js'
import moment from 'moment-timezone';
import vSelect from 'vue-select';
import {FormWizard, TabContent} from 'vue-form-wizard';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Datepicker from "vuejs-datepicker";
import axios from "@/axios.js";

export default {
  data:() => ({
    selected: [],
    showCreateResourceModal: false,
    log: [],
    resource: {
      type: '',
      name: '',
      email: '',
      timezone: '',
      target: '',
      flowType: '',
      status: '',
      location: '',
      resource: '',
    },
    startTime: '09:00',
    endTime: '09:30',
    startDate: null,
    endDate: null,
    disabledDates: {
      to: new Date(Date.now() - 8640000)
    },
    dayOfWeek: 'Sunday',
    daysOfWeek: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ],
    currentSelectedType: null,
    practioners: [],
    practionerScheduleForm: {
      "docstatus": 0,
      "doctype": "Practitioner Schedule",
      "owner": "Administrator",
      "disabled": 0,
      "allow_video_conferencing": 0,
      "schedule_name": "Sport Examination only",
      "custom_base_url": "demo.healminder.com",
      "time_slots": [
        {
          "docstatus": 0,
          "doctype": "Healthcare Schedule Time Slot",
          "owner": "Administrator",
          "day": "Monday",
          "parentfield": "time_slots",
          "parenttype": "Practitioner Schedule",
          "idx": 1,
          "from_time": "09:00:00",
          "to_time": "09:30:00"
        },
        {
          "docstatus": 0,
          "doctype": "Healthcare Schedule Time Slot",
          "owner": "Administrator",
          "day": "Monday",
          "parentfield": "time_slots",
          "parenttype": "Practitioner Schedule",
          "idx": 2,
          "from_time": "09:30:00",
          "to_time": "10:00:00"
        },
        {
          "docstatus": 0,
          "doctype": "Healthcare Schedule Time Slot",
          "owner": "Administrator",
          "day": "Wednesday",
          "parentfield": "time_slots",
          "parenttype": "Practitioner Schedule",
          "idx": 3,
          "from_time": "09:00:00",
          "to_time": "09:30:00"
        },
        {
          "docstatus": 0,
          "doctype": "Healthcare Schedule Time Slot",
          "owner": "Administrator",
          "day": "Wednesday",
          "parentfield": "time_slots",
          "parenttype": "Practitioner Schedule",
          "idx": 4,
          "from_time": "09:30:00",
          "to_time": "10:00:00"
        }
      ]
    }
  }),
  components: {
    vSelect, FormWizard, TabContent, VueTimepicker, Datepicker
  },
  methods:{
    clearFields() {
      this.personnelResourceAvailableTimeslots = [];
      this.resource.type = '';
      this.resource.name = '';
      this.resource.email = '';
      this.resource.timezone = '';
      this.resource.target = '';
      this.resource.flowType = '';
      this.resource.status = '';
      this.resource.location = '';
      this.resource.resource = '';
      this.startTime = '09:00';
      this.endTime = '09:30';
      this.startDate = null;
      this.endDate = null;
      this.dayOfWeek = 'Sunday';
      this.$validator.reset();
    },
    showModal(step = null) {
      if (step) {
        this.$refs.createResourceWizard.changeTab(step - 1, step);
      } else {
        this.$refs.createResourceWizard.changeTab(0, 0);
      }
      this.showCreateResourceModal = true;
    },
    showCreateTimeslotsModal(id, type) {
      if (type === 'personnel') {
        this.currentSelectedType = 'personnel';
        this.$store.commit('moduleResource/SET_NEW_PERSONAL_RESOURCE_ID', id);
        this.$vs.loading({
          container: '#div-with-loading',
          scale: 0.6
        })
        this.$store.dispatch("moduleResource/getPersonnelResourceTimeslots", {'id': id})
          .then(() => {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
            console.error(err);
          });
      }

      if (type === 'equipment') {
        this.currentSelectedType = 'equipment';
        this.$store.commit('moduleResource/SET_NEW_EQUIPMENT_RESOURCE_ID', id);
        this.$vs.loading({
          container: '#div-with-loading',
          scale: 0.6
        })
        this.$store.dispatch("moduleResource/getEquipmentResourceTimeslots", {'id': id})
          .then(() => {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
            this.$vs.loading.close();
          })
          .catch(err => {
            this.$vs.loading.close('#div-with-loading > .con-vs-loading')
            console.error(err);
          });
      }

      this.showModal(1);
    },
    goToConfigureResourceStep() {
      return new Promise(() => {
        this.$validator.validateAll('create-resource-main-info').then(result => {
          if (result) {
            this.$vs.loading({
              container: '#div-with-loading',
              scale: 0.6
            })
            let payload = {};
            if (this.resource.type === 'person') {

              let formData = new FormData()
              formData.append('doc', JSON.stringify({
                  "docstatus": 0,
                  "doctype": "Healthcare Practitioner",
                  "owner": "Administrator",
                  "status": "Active", // to form dialog
                  "custom_base_url": "demo.healminder.com",
                  "custom_resource_type": "Personnel",
                  "custom_category": "Consultation",
                  "practitioner_type": "Internal",
                  "default_currency": "EUR", // take it from company data
                  "first_name": this.resource.name,
                  "department": "Preventive Care",
                  "hospital": "HealMinder", // Company name
                  "practitioner_schedules": [
                    {
                      "docstatus": 0,
                      "doctype": "Practitioner Service Unit Schedule",
                      "schedule": "HealMinder Annual Check Up",
                      "service_unit": "Women 2 Service+Unit - H"
                    }
                  ]
                }
              ))
              formData.append('action', 'Save')

              axios.post(`${process.env.VUE_APP_GATEWAY_URL}/api/method/frappe.desk.form.save.savedocs`, formData, {
                headers: {
                  'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Accept: 'application/json',
                },
              }).then(() => {
                this.fetchPractioners()
                this.$vs.loading.close()
                this.showCreateResourceModal = false
              })

            }
            if (this.resource.type === 'item-asset') {
              payload.payload = {
                "availabilityIntervalIndexs": [],
                "availabilityIntervals": [],
                "costOfPurchase": 10,
                "costRate": 10,
                "depreciationCost": 90,
                "groupType": "string9",
                "maintainmentCost": 10,
                "name": this.resource.name,
                "organizationId": 9,
                "timeOfPurchase": ["","","","","","","",""],
                "totalEquipmentCost": 10,
                "totalTime": 10,
                "type": "string3",
                "resourceUsageIds": ["","","","","","","",""]
              };
              payload.type = 'equipment';
            }
            // this.$store.dispatch("moduleResource/createResource", payload)
            //   .then(() => {
            //     this.$vs.loading.close('#div-with-loading > .con-vs-loading');
            //     this.showCreateResourceModal = false;
            //     this.clearFields();
            //     this.$vs.notify({
            //       color: 'success',
            //       title: 'Success',
            //       text: 'Resource created successfully'
            //     })
            //     //this.$refs.createResourceWizard.nextTab();
            //   })
            //   .catch(err => {
            //     console.log(err);
            //     this.$vs.loading.close('#div-with-loading > .con-vs-loading');
            //     this.showCreateResourceModal = false;
            //     this.clearFields();
            //   });
          }
        });
      })
    },
    goToAddTimeslotsStep() {
      return new Promise(() => {
        this.$validator.validateAll('create-resource-details').then(result => {
          if (result) {
            this.$refs.createResourceWizard.nextTab();
          }
        });
      })
    },
    addTimeslot() {
      return new Promise(() => {
        this.$validator.validateAll('add-timeslot').then(result => {
          if (result) {
            this.$vs.loading({
              container: '#div-with-loading',
              scale: 0.6
            });
            const startTimeData = this.startTime.split(':');
            const endTimeData = this.endTime.split(':');
            const endTimeModified = moment(Date.now()).set('hour', endTimeData[0]).set('minute', endTimeData[1]).set('second', 59).subtract({minutes: 1});
            const payload = {
              "weeklyScheduleAvailabilityIntervalsForDays": [
                {
                  "availabilityIntervals": [
                    {
                      "availability": "yes",
                      "availabilityIntervalId": "string",
                      "endFullTime": [
                        0,0,0,endTimeModified.get('hour'),endTimeModified.get('minute'),endTimeModified.get('second'),1,0
                      ],
                      "note": "string",
                      "startFullTime": [
                        0,0,0,parseInt(startTimeData[0]),parseInt(startTimeData[1]),0,1,0
                      ],
                      "type": "string"
                    }
                  ],
                  "dayOfWeek": this.dayOfWeek
                }
              ]
            };

            if (this.currentSelectedType == 'personnel') {
              this.$store.dispatch("moduleResource/generatePersonnelResourceTimeslots", {
                'payload': payload,
                'startFullTime': moment(this.startDate).format(),
                'endFullTime': moment(this.endDate).format()
              })
                .then(() => {
                  this.$vs.loading.close('#div-with-loading > .con-vs-loading')
                  // return this.$router.push('/apps/preventiveHealth/booking-success');
                })
                .catch(err => {
                  console.error(err);
                  this.$vs.loading.close('#div-with-loading > .con-vs-loading')
                });
            }

            if (this.currentSelectedType == 'equipment') {
              this.$store.dispatch("moduleResource/generateEquipmentResourceTimeslots", {
                'payload': payload,
                'startFullTime': moment(this.startDate).format(),
                'endFullTime': moment(this.endDate).format()
              })
                .then(() => {
                  this.$vs.loading.close('#div-with-loading > .con-vs-loading')
                  // return this.$router.push('/apps/preventiveHealth/booking-success');
                })
                .catch(err => {
                  console.error(err);
                  this.$vs.loading.close('#div-with-loading > .con-vs-loading')
                });
            }
          }
        });
      })
    },
    fetchPractioners() {
      axios.get(process.env.VUE_APP_GATEWAY_URL+`/api/resource/Healthcare Practitioner?fields=["*"]&filters=[["custom_base_url","=","${window.location.host === 'localhost:8080' ? 'demo.healminder.com' : window.location.host }"]]`, {
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }).then(response => {
        this.practioners = response.data.data;
      })
    },
    createPractionerSchedule() {
      console.log(this.practionerScheduleForm)
      let formData = new FormData()
      formData.append('doc', JSON.stringify(this.practionerScheduleForm))
      formData.append('action', 'Save')

      axios.post(`${process.env.VUE_APP_GATEWAY_URL}/api/method/frappe.desk.form.save.savedocs`, formData, {
        headers: {
          'Authorization': `token ${process.env.VUE_APP_FRAPPE_CLIENT_ID}:${process.env.VUE_APP_FRAPPE_CLIENT_SECRET}`,
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
      }).then(() => {
        // this.fetchPractioners()
        this.$vs.loading.close()
        this.showCreateResourceModal = false
      })
    },
    addNewScheduleForm() {
      this.practionerScheduleForm.time_slots.push(
        {
          "docstatus": 0,
          "doctype": "Healthcare Schedule Time Slot",
          "owner": "Administrator",
          "day": "",
          "parentfield": "time_slots",
          "parenttype": "Practitioner Schedule",
          "idx": 1,
          "from_time": '',
          "to_time": ""
        },
      )
    },
    removeSchedule(index) {
      this.practionerScheduleForm.time_slots.splice(index, 1)
    }
  },
  created() {
    if (!moduleResource.isRegistered) {
      this.$store.registerModule('moduleResource', moduleResource)
      moduleResource.isRegistered = true
    }
    this.fetchPractioners()
    // this.$store.dispatch("moduleResource/fetchPersonnelResources", this.payload);
    // this.$store.dispatch("moduleResource/fetchEquipmentResources", this.payload)
  },
  computed: {
    resourcePersonnelData() {
      return this.$store.state.moduleResource.resourcesPersonnel;
    },
    resourceEquipmentData() {
      return this.$store.state.moduleResource.resourcesEquipment;
    },
    personnelResourceAvailableTimeslots: {
      get() { return this.$store.state.moduleResource.personnelResourceAvailableTimeslots; },
      set(newValue) { this.$store.commit('moduleResource/SET_PERSONAL_RESOURCE_AVAILABILITY_INTERVALS', newValue); }
    },
    timezones() {
      return moment.tz.names();
    },
    canAddTimeslot() {
      return this.startDate && this.endDate && this.endTime && this.startTime && this.dayOfWeek;
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";

.resource-list-dashboard-container {
  max-width: 1400px;
  margin: 0 auto;

  .resources-list {
    margin: 0 35px;
    padding: 30px 0;

    h4 {
      padding-bottom: 20px;
    }

    .resources-list-header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 15px;

      .create-resource-button {
        border-radius: 0;
        text-transform: none;
        font-size: 1rem;
        padding: 12px 15px;
      }
    }

    .resources-list-content {
      box-shadow: 4px 4px 8px #ddd;

      .vs-con-table .vs-con-tbody {
        border: none;
      }

      tr {
        border-bottom: 1px solid #ddd;
        font-size: .85rem;
      }

      .vs-table--tbody-table .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
        z-index: 200;
        -webkit-transform: none;
        transform: none;
      }

      .vs-table--td {
        color: $textColor;
      }
    }
  }
}

.create-resource-modal {
  .vx-row {
    //width: 100%;
  }
  .vs-popup--content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .vue-form-wizard {
      width: 100%;
    }

    .wizard-nav {
      display: none;
    }

    h4 {
      margin-bottom: 25px;
    }

    .create-resource-form-general {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0 25px;

      .resource-type {
        display: flex;
        justify-content: space-around;
        .vs-radio--label {
          display: flex;
          flex-direction: column;
          align-self: flex-start;
        }
        label {
          border: 1px solid $darkGreen;
          color: $darkGreen;
          padding: 20px 20px;
          border-radius: 4px;
          max-width: 160px;
          .resource-type-title {
            font-weight: 600;
            padding-bottom: 12px;
            align-self: flex-start;
          }
          .resource-type-description {
            font-size: 12px;
            line-height: 1.1rem;
          }
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
          &:hover {
            color: $lightGreen;
            border: 1px solid $lightGreen;
          }
          &.selected-type {
            color: $lightGreen;
            border: 1px solid $lightGreen;
          }
        }
        span.vs-radio {
          opacity: 0 !important;
          width: 0 !important;
        }
        //.text-danger {
        //  flex-grow: 1;
        //  flex-shrink: 1;
        //  flex-basis: 100%;
        //}
      }
      .timezone-container {
        margin-top: 30px  !important;
      }
    }
    .create-resource-form-details {

    }
    .create-resource-add-timeslots {
      margin: 0 20px;
      min-height: 500px;
      .recurring-working-hours-description {
        font-size: 12px;
      }
      .recurring-working-hours-widget-container {
        .add-recurring-working-hours-title {
          margin: 10px 0;
          font-size: 13px;
          color: $lightGreen;
          &:last-child {
            margin-bottom: 200px;
          }
        }
        .recurring-working-hours-widget {
          .resource-field-container {
            width: auto;
            display: flex;
            flex-direction: column;
          }
          //.start-time-slot-widget, .end-time-slot-widget  {
          //  margin-right: 3px;
          //}
          .add-timeslot-btn {
            height: 37px;
            background: $white;
            outline: none;
            border: 1px solid $lightGreen;
            padding: 0 15px;
            color: $lightGreen;
            &:hover {
              background-color: rgba(6, 178, 164, 0.2);
              cursor: pointer;
            }
            &.disabled-btn {
              border: 1px solid #ddd;
              color: #626262;
              &:hover {
                background-color: rgba(62, 62, 62, 0.1);
              }
            }
          }
          .v-select {
            min-width: 150px;
          }
        }
        .recurring-working-hours-list-container {
          overflow: hidden;
          .recurring-working-hours-list {
            font-size: 12px;
            margin-bottom: 20px;
            overflow: auto;
            height: 200px;
            scrollbar-width: none; /* Firefox 64 */
            -ms-overflow-style: none; /* Internet Explorer 11 */
            &::-webkit-scrollbar { /** WebKit */
              display: none;
            }
            .recurring-working-hours-item {
              display: flex;
              justify-content: space-between;
              margin: 12px 0;
              font-size: 14px;
              .vs-icon {
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
        .start-date-label, .end-date-label {
          margin-bottom: 8px;
          display: inline-block;
          font-size: 14px;
        }
      }
      .vdp-datepicker__calendar {
        width: 250px;
        border-radius: 0;
        span.cell:not(.disabled):hover {
          border-color: rgba(0, 0, 0, 0) !important;
          background-color: white !important;
          color: $lightGreen;
        }
        span.cell.disabled:hover {
          border-color: rgba(0, 0, 0, 0) !important;
          background-color: white !important;
        }
        span.cell.selected {
          color: $lightGreen;
          background-color: white !important;
        }
        //.vdp-datepicker {
        //  .cell:hover {
        //    border-color: rgba(0, 0, 0, 0) !important;
        //    background-color: black !important;
        //  }
        //}
      }
    }
  }
  .vs-input--input {
    height: 40px;
  }
  .vs-con-input-label {
    width: auto;
  }
  .resource-field-container {
    width: 30%;
  }
  .vue__time-picker {
    input {
      height: 37px;
    }
    input {
      border-color: $green;
    }
  }
  .vs__dropdown-menu {
    min-width: 100px;
  }
}
.con-vs-popup {
  z-index: 9999999;
}
.text-danger {
  font-size: 12px;
}

</style>
